import { Component, OnInit } from "@angular/core";
import { Toaster } from "ngx-toast-notifications";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-chart-shipment-status",
  templateUrl: "./chart-shipment-status.component.html",
  styleUrls: ["./chart-shipment-status.component.scss"],
})
export class ChartShipmentStatusComponent implements OnInit {
  dataShipment: any;
   bulan:Array<string>=['JAN','FEB','MAR','APR','MEI','JUN','JUL','AGU','SEP','OKT','NOV','DES'];
  filter = {
    startDate: new Date(),
    endDate: new Date(),
  };
  shipmentChart = {
    chart: {
      height: 340,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "os Incoming WH",
        data: [],
      },
      {
        name: "os Manifest",
        data: [],
      },
      {
        name: "os Keberangkatan",
        data: [],
      },
      {
        name: "os Kedatangan",
        data: [],
      },
      {
        name: "os Delivery",
        data: [],
      },
      {
        name: "os Penerima",
        data: [],
      },
    ],
    xaxis: {
      categories: [],
    },
    colors: ["#f10b31", "#99970f", "#6ca7eb",'#4099ff','#5c08bd','#0fa00f'],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  constructor(private apiService: ApiService, private toaster: Toaster) {}

  ngOnInit(): void {
    this.filter.startDate = new Date();
    this.filter.endDate = new Date();
    this.getData();
  }

  async getData() {
    const res: any = await this.apiService.getDashboardShipmentByPeriods(
      null,
      null
    );
    console.log(res);
    if (res.success) {
      this.dataShipment =res.data;
      console.log(this.dataShipment)
      this.createChart(this.dataShipment);
    } else {
      this.toaster.open({ type: "danger", text: "Error" });
    }
  }
  createChart(data:Array<{}>){
    
    data.forEach( (value)=> {
      console.log(new Date(value['month']).getFullYear());

      this.shipmentChart.xaxis.categories.push(`${this.bulan[new Date(value['month']).getMonth()]} ${new Date(value['month']).getFullYear()}`)
      this.shipmentChart.series[0].data.push(value['os_incoming_wh']);
      this.shipmentChart.series[1].data.push(value['os_manifest']);
      this.shipmentChart.series[2].data.push(value['os_departure']);
      this.shipmentChart.series[3].data.push(value['os_arrival']);
      this.shipmentChart.series[4].data.push(value['os_delivery']);
      this.shipmentChart.series[5].data.push(value['os_received']);

      
    });
  }
}
