import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';

import { ModalShipmentComponent } from 'src/app/modals/modal-shipment/modal-shipment.component';

@Component({
  selector: 'app-dashboard-shipment',
  templateUrl: './dashboard-shipment.component.html',
  styleUrls: ['./dashboard-shipment.component.scss']
})
export class DashboardShipmentComponent implements OnInit {

  @Input() customerId:string=null;
  summaryShipment:any={
     
  };

  constructor(
    private apiService:ApiService,
    private toaster:Toaster,
    private modal:NgbModal
  ) {}

  ngOnInit(): void {
    
    this.getSummaryShipment();
  }

  openModal(type:string='SHIPMENT') {
    const modalRef = this.modal.open(ModalShipmentComponent, {
      size:'xl',
      keyboard: false,
      backdrop: 'static'
    }); 
    modalRef.componentInstance.fromParent = {
      type,
      cid:this.customerId
    };
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  async getSummaryShipment(){

    const res:any = await this.apiService.getDashboardShipment(this.customerId);
    if (res.success){
      this.summaryShipment={
        ...res.data
      }

    }else{
      this.toaster.open({type:'danger',text:'Error'})
    }


  }
  

}
