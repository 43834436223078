
<div class="card">
    
    <!-- <div class="spinner-border spinner text-primary m-1" role="status">
        <span class="sr-only">Loading...</span>
    </div> -->
    <div class="card-body">
        
        <div class="d-sm-flex flex-wrap">
            <h4 class="card-title mb-4">Pengiriman Periode </h4>
            
            <div class="ms-auto">
               
                <!-- <div class="row">
                    <div class="col-6">
                        <div class="input-group clockpicker">
                            <input ngbDatepicker class="form-control" [(ngModel)]="filter.startDate" placeholder="yyyy-mm-dd" [(ngModel)]="filter.startDate" #startDate="ngbDatepicker">
                            <div class="input-group-append" (click)="startDate.toggle()">
                              <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                            </div>
                          </div>
                    </div>
                    <div class="col-6">
                        <div class="input-group clockpicker">
                            <input ngbDatepicker class="form-control"  [(ngModel)]="filter.endDate" placeholder="yyyy-mm-dd" #endDate="ngbDatepicker">
                            <div class="input-group-append" (click)="endDate.toggle()">
                              <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                            </div>
                          </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-datepicker></app-datepicker>
            </div>

        </div>
        
        <apx-chart dir="ltr" class="apex-charts" [chart]="shipmentChart.chart"
        [series]="shipmentChart.series" [legend]="shipmentChart.legend"
        [colors]="shipmentChart.colors" [fill]="shipmentChart.fill"
        [dataLabels]="shipmentChart.dataLabels" [xaxis]="shipmentChart.xaxis"
        [plotOptions]="shipmentChart.plotOptions">
    </apx-chart>
        
    </div>
</div>
