import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPrintComponent } from 'src/app/modals/modal-print/modal-print.component';

@Component({
  selector: 'app-sa-invoice-list',
  templateUrl: './sa-invoice-list.component.html',
  styleUrls: ['./sa-invoice-list.component.scss']
})
export class SaInvoiceListComponent implements OnInit {


  @Input() dataList:any;
  @Input() type:string;
  constructor(
    private router:Router,
    private modal:NgbModal
  ) { }

  ngOnInit(): void {
    console.log(this.dataList);
  }
  createPayment(invoice_id:string){
    this.router.navigate(['/sa-finance/paid-invoice'],{ queryParams: { invoice_id} })
  }
  showDataDetail(i:any){
    if (!this.dataList[i]['showDetail']){
      this.dataList[i]['showDetail']=true;
    }else{
      this.dataList[i]['showDetail']=false;
    }
  }
  openPrintModal(typePrint:string,id:string){
    const modalRef = this.modal.open(ModalPrintComponent, {
      // scrollable: true,
      size:'xl',
    }); 
    modalRef.componentInstance.fromParent ={
      format:typePrint,
      id,
      report:'INVOICE'
    };
    modalRef.result.then((result) => {
    }, (reason) => {
      console.log(reason);
    });
  }

  gotoPage(invoice:any){

  }

}
