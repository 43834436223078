<div class="modal-header">
    <h5 class="modal-title mt-0">Detail Shipment No Manifest / Surat Jalan {{manifestNo}}</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal(false)"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">

    <div class="row">
      <div   class="container">
        <p class="h2">Informasi</p>
        <div class="row">
          <div class="col">
            <p class="h6">Berat : </p>
            <p class="text-muted">{{countData?.berat | number}} Kg</p>
          </div>
          <div class="col">
            <p class="h6">Qty</p>
            <p class="text-muted">{{countData?.qty}} Koli</p>
          </div>
          <div class="col">
            <p class="h6">Total </p>
            <p class="text-muted">RP {{countData?.total | number}}</p>
          </div>
          <div class="col">
         
           
        </div>
      </div>

    </div>
    <div class="table-responsive">
        <table class="table shipment-list-table table-nowrap align-text-top table-borderless">
          <thead style="position: sticky;top: 0">
            <tr>
              <th scope="col" class="lebar">#</th>
              <th scope="col" class="lebar">No Resi</th>
              <th scope="col">Pickup</th>
              <th scope="col">Delivery</th>
              <th scope="col" class="lebar">Layanan</th>
            </tr>
          </thead>
          <tbody  *ngFor="let n of dataList?.detail_manifest; let i = index">
            <tr>
                <td style="vertical-align: middle;">
                      <img *ngIf="n.shipment_detail?.moda_id=='A'" src="assets/images/air-freight.png" alt class="avatar-sm" />
                      <img *ngIf="n.shipment_detail?.moda_id=='L'" src="assets/images/land-trucking.png" alt class="avatar-sm" />
                      <img *ngIf="n.shipment_detail?.moda_id=='S'" src="assets/images/sea-freight.png" alt class="avatar-sm" />
                </td>
                <td style="vertical-align: middle;">
                  <span *ngIf="n.shipment_detailis_cash" class="badge bg-success float-start" ><i class="bx bx-money"></i></span>
                  <p class="mb-0"><a href="javascript:void(0)" (click)="showDataDetail(i)"> {{n.shipment_detail.shipment_awbm}}</a></p>
                   <p class="text-muted">{{n.shipment_detail.shipment_date  | date : "dd-MM-yyyy" }}</p>
                <td>
                  <span><i class="bx bx-right-top-arrow-circle font-size-20 text-primary"></i>{{n.shipment_detail.origin}}-{{n.shipment_detail.originname}} </span>
                  <p class="text-black mb-0 text-wrap">{{n.shipment_detail.partner_name}}</p>
                  <p class="text-muted mb-0 text-wrap">{{n.shipment_detail.partner_address1}}</p>
                </td>
                <td>
                  <span><i class="bx bx-right-down-arrow-circle font-size-20 text-primary"></i>{{n.shipment_detail.destination}}-{{n.shipment_detail.destinationname}} </span>
                  <p class="text-black mb-0 text-wrap">{{n.shipment_detail.receiver_name}}</p>
                  <p class="text-muted mb-0 text-wrap">{{n.shipment_detail.receiver_address1}}</p>
                </td>
                <td>
                  <p class="mb-0 ">{{n.shipment_detail.service_id}}</p>
                  <p class="text-black mb-0 text-wrap">{{n.shipment_detail.weight|number}} {{n.shipment_detail.uom_id}}</p>
                  <p *ngIf="n.shipment_detail.moda_id=='A'">Udara</p>
                  <p *ngIf="n.shipment_detail.moda_id=='L'">Darat</p>
                  <p *ngIf="n.shipment_detail.moda_id=='S'">Laut</p>
                  <span class="badge badge-pill badge-soft-info font-size-11" [ngClass]=" { 'badge-soft-danger': n.shipment_detail.last_status === 'PICKUP',
                  'badge-soft-warning':n.shipment_detail.last_status === 'MANIFEST' }"> {{n?.last_status_remark}}</span>
                </td>
                
            </tr>
            
          </tbody>
        </table>
      </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal(false)">Tidak</button>
  </div>