import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule, NgbModalModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StatComponent } from './stat/stat.component';
import { TransactionComponent } from './transaction/transaction.component';
import { ChartShipmentStatusComponent } from './chart-shipment-status/chart-shipment-status.component';
import { DashboardShipmentComponent } from './dashboard-shipment/dashboard-shipment.component';
import { UIModule } from '../ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MasterCustomerComponent } from './master-customer/master-customer.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { MasterHubComponent } from './master-hub/master-hub.component';
import { MasterCustomerHargaComponent } from './master-customer-harga/master-customer-harga.component';
import { NgxMaskModule } from 'ngx-mask';
import { MasterVendorComponent } from './master-vendor/master-vendor.component';
import { MasterVendorHargaComponent } from './master-vendor-harga/master-vendor-harga.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { ShipmentEntryComponent } from './shipment-entry/shipment-entry.component';
import { MapShipmentComponent } from './map-shipment/map-shipment.component';
import { GridProvinsiComponent } from './grid-provinsi/grid-provinsi.component';
import { MasterDriverComponent } from './master-driver/master-driver.component';
import { HeaderPrintComponent } from './header-print/header-print.component';
import { MasterContactComponent } from './master-contact/master-contact.component';
import { SetUserComponent } from './set-user/set-user.component';
import { MasterCabangComponent } from './master-cabang/master-cabang.component';

@NgModule({
  declarations: [StatComponent, 
    TransactionComponent,
    ChartShipmentStatusComponent,
    DashboardShipmentComponent, 
    MasterCustomerComponent, 
    MasterHubComponent, 
    MasterCustomerHargaComponent, 
    MasterVendorComponent, MasterVendorHargaComponent,
    ShipmentComponent, ShipmentEntryComponent,
    MapShipmentComponent,
    GridProvinsiComponent,
    MasterDriverComponent,
    HeaderPrintComponent,
    MasterContactComponent,
    SetUserComponent,
    MasterCabangComponent
   
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    FormsModule,
    UIModule,
    AutocompleteLibModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgxMaskModule
  ],
  exports: [  
              StatComponent, 
              TransactionComponent,
              ChartShipmentStatusComponent,
              DashboardShipmentComponent,
              MasterCustomerComponent,
              MasterHubComponent,
              MasterCustomerHargaComponent,
              MasterVendorComponent,
              MasterVendorHargaComponent,
              MasterDriverComponent,
              ShipmentComponent,
              ShipmentEntryComponent,
              MapShipmentComponent,
              GridProvinsiComponent,
              HeaderPrintComponent,
              MasterContactComponent,
              SetUserComponent,
              MasterCabangComponent
            
            ]
})
export class WidgetModule { }
