<div class="table-responsive">
    <table   class="table mb-0" >
        <thead class="thead-dark">
            <tr>
                <th>No</th>
                <th>#</th>

                <th sortable="shipment_awbm" >No Resi</th>
                <th sortable="shipment_date" >Tanggal</th>
                <th sortable="shipment_do" >Reff</th>
                <th sortable="origin" >Asal</th>
                <th sortable="destination" >Tujuan</th>
                <th sortable="partner_name" >Pengirim</th>
                <th sortable="receiver_name" >Penerima</th>
                <th sortable="moda_id" >Moda</th>
                <th sortable="service_id" >Layanan</th>
                <th sortable="weight" >Berat</th>
                <th sortable="qty" >koli</th>
                <th sortable="qty" >Total</th>
            </tr>
        </thead>
        <tbody *ngFor="let n of dataList;let i = index">
            <tr>
              <td> {{count + (i+1)}}</td>
                <td>
                   
                    <img *ngIf="n?.moda_id=='A'" src="assets/images/air-freight.png" alt class="avatar-sm" />
                    <img *ngIf="n?.moda_id=='L'" src="assets/images/land-trucking.png" alt class="avatar-sm" />
                    <img *ngIf="n?.moda_id=='S'" src="assets/images/sea-freight.png" alt class="avatar-sm" />
              </td>
              <td>
                <p class="mb-0"><a (click)="gotoPage(n.shipment_awbm)"  href="javascript:void(0)"> {{n.shipment_awbm}}</a></p>
               </td>
              <td> <p class="text-muted">{{n.shipment_date  | date : "dd-MM-yyyy" }}</p></td>
              <td>   <p class="text-muted">{{n.shipment_do }}</p></td>
              <td>{{n.origin}}-{{n.originname}}</td>
              <td>{{n.destination}}-{{n.destinationname}}</td>
              <td>{{n.partner_name}} ({{n.partner_id}})</td>
              <td>{{n.receiver_name}}</td>
              <td>{{n.moda_id}}</td>
              <td>{{n.service_id}}</td>
              <td>{{n.weight}} {{n.uom_id}}</td>
                <td>{{n.qty}}</td>
                <td class="text-right">{{n.total|number}}</td>
                <td class="text-center">
                  
                  <div class="dropdown" ngbDropdown placement="bottom-right">
                    <a href="javascript: void(0);" class="dropdown-toggle card-drop" ngbDropdownToggle>
                      <i class="mdi mdi-dots-vertical font-size-18"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                          
                          <button  type="button" class="btn btn-sm btn-outline-warning" *ngIf="!n.is_paid"  ngbTooltip="Validasi" (click)="openValidasiModal()"><i class="bx bx-money"></i></button>
                          <button type="button" class="btn btn-sm btn-outline-info"  ngbTooltip="Cetak Resi " (click)="openPrintModal('RESI_1',n.shipment_awb)"><i class="bx bx-printer"></i></button>
    
                          <button type="button" class="btn btn-sm btn-outline-success"  ngbTooltip="Cetak Resi " (click)="openPrintModal('RESI_2',n.shipment_awb)"><i class="bx bxs-printer"></i></button>
                    </div>
                  </div>

                   
                    
                    
                  </td>
            </tr>
            <tr *ngIf="n.showDetail">
            </tr>
        </tbody>
    </table>
</div>
