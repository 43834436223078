import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPrintComponent } from 'src/app/modals/modal-print/modal-print.component';
import { ModalValidasiComponent } from '../modal-validasi/modal-validasi.component';

@Component({
  selector: 'app-sa-shipment',
  templateUrl: './sa-shipment.component.html',
  styleUrls: ['./sa-shipment.component.scss']
})
export class SaShipmentComponent implements OnInit {

  @Input() dataList:any;
  @Input() type:string;
  @Input() count:number;
 
   
  constructor(
    private router:Router,
    private modal:NgbModal,
  ) { }

  ngOnInit(): void {
   
    console.log(this.count)
  }


  gotoPage(shipment:any){
    console.log(this.type)
    if (this.type==='ZERO' || this.type==='UNINVOICE' ){
      this.router.navigate(['/sa-shipment/validation',shipment])
    }
    if (this.type=='CASH'){
      this.router.navigate(['/sa-shipment/validation-cash',shipment])
    }
    

   
    // this.router.navigate(['/arrival/arrival-confirm'],{ queryParams: {assignment_id,manifest_id } })

  }
  showDataDetail(i:any){
    if (!this.dataList[i]['showDetail']){
      this.dataList[i]['showDetail']=true;
    }else{
      this.dataList[i]['showDetail']=false;
    }
  }
  
  openPrintModal(typePrint:string,id:string){
    const modalRef = this.modal.open(ModalPrintComponent, {
      // scrollable: true,
      size:'xl',
    }); 
    modalRef.componentInstance.fromParent ={
      format:typePrint,
      id,
      report:'RESI'
    };
    modalRef.result.then((result) => {
    }, (reason) => {
      console.log(reason);
    });
  }

  openValidasiModal(){
    const modalRef = this.modal.open(ModalValidasiComponent, {
      // scrollable: true,
      size:'xl',
    }); 
    modalRef.componentInstance.fromParent ={
      
      
    };
    modalRef.result.then((result) => {
    }, (reason) => {
      console.log(reason);
    });
  }

}
