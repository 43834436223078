<div class="row">

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Informasi Resi</h4>
                            <hr />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" [hidden]="true">
                                        <label for="cn" class="col-sm-3 form-control-label">Otomatis</label>
                                        <div class="col-sm-6">
                                            <input [ngModel]="DO.shipment_awb" type="text" class="form-control" id="cn" name="cn" placeholder="Auto Generate" disabled>
                                        </div>
                                    </div>
                                    <div class="mb-0 row">
                                        <label for="address1" class="col-md-3 col-form-label"></label>
                                        <div class="col-sm-9">
                                            <div class="form-group form-check">
                                                <input type="checkbox" id="is_incoming_wh" name="is_incoming_wh" [ngModel]="isIncomingWh" class="form-check-input" [checked]="isIncomingWh" />
                                                <label for="is_incoming_wh" class="form-check-label">Check jika shipment sudah di pickup</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-1 row">
                                        <label for="shipment_awbm" class="col-md-3 col-form-label">No Resi <span class="text-primary">*</span></label>
                                        <div class="col-md-9">
                                            <div class="input-group mb-1">
                                                <input type="text" id="shipment_awbm" name="shipment_awbm" class="form-control" aria-label="shipment_awbm" [disabled]="isAutoNumber" [(ngModel)]="DO.shipment_awbm">
                                                <div class="input-group-text">
                                                    <input class="form-check-input mt-0" id="isAutoNumber" name="isAutoNumber" type="checkbox" value="" aria-label="isAutoNumber" [(ngModel)]="isAutoNumber" [checked]="isAutoNumber">
                                                    <label for="isAutoNumber" class="form-check-label"> Auto Number</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-1 row">
                                        <label for="shipment_date" class="col-md-3 col-form-label">Tanggal<span class="text-primary">*</span></label>
                                        <div class="col-md-4">
                                            <input class="form-control" type="date" [ngModel]="DO.shipment_date  | date:'yyyy-MM-dd'" (ngModelChange)="onChangeDate($event,'shipment_date')" id="shipment_date" name="shipment_date" required>
                                        </div>
                                    </div>
                                    <div class="mb-1 row">
                                        <label for="shipment_do" class="col-md-3 col-form-label">No Reff</label>
                                        <div class="col-md-9">
                                            <input class="form-control" type="text" value="" [(ngModel)]="DO.shipment_do" id="shipment_do" name="shipment_do">
                                        </div>
                                    </div>
                                    <div class="mt-2 row">
                                        <label class="col-md-3 col-form-label">Metode Pembayaran</label>
                                        <div class="col-md-4">
                                            <select class="form-select" [(ngModel)]="DO.shipment_type_id" id="payment_method" name="payment_method">
                                            <option value="CASH">Cash</option>
                                            <option value="CREDIT">Invoice</option>
                                            <option value="COLLECT">Collect</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-1 row">
                                        <label class="col-md-3 col-form-label">Moda<span class="text-primary">*</span><span><i class="fi flaticon-airplane-frontal-view text-primary font-size-15" [ngClass]="{'flaticon-airplane-frontal-view': DO.moda_id === 'A', 'flaticon-delivery-truck' :  DO.moda_id === 'L' ,'flaticon-sea-ship': DO.moda_id === 'S'}"></i></span></label>
                                        <div class="col-md-4">
                                            <select class="form-select" [(ngModel)]="DO.moda_id" (ngModelChange)="onChangeModa($event)" id="moda_id" name="moda_id">
                                                <option [value]="i?.moda_id" *ngFor="let i of modaData">{{i.moda_desc}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="mb-1 row">
                                        <label for="service_id" class="col-md-3 col-form-label">Layanan<span class="text-primary">*</span></label>
                                        <div class="col-md-6">
                                            <input class="form-control" type="text" [(ngModel)]="DO.service_id" id="service_id" name="service_id" list="service_list">
                                            <datalist id="service_list">
                                                <option [value]="i?.service_id" *ngFor="let i of serviceData">{{i.service_desc}}</option>
                                            </datalist>
                                        </div>
                                    </div>
                                    <div class="mb-1 row">
                                        <label for="pickup_id" class="col-md-3 col-form-label">Pickup By <span class="text-primary">*</span> </label>
                                        <div class="col-md-4">
                                            <div class="input-group">
                                                <input type="text" class="form-control" id="pickup_id" name="pickup_id" [(ngModel)]="DO.pickup_id" ng aria-describedby="pickup_id" disabled="true" />
                                                <button class="btn btn-warning" type="button" id="btn_pickup_id" (click)="openContact('DRIVER')"><i class="fi flaticon-24-hours-delivery"></i></button>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <input type="text" class="form-control" id="pickup_by" name="pickup_by" [(ngModel)]="DO.pickup_by" placeholder="Pickup By" disabled="true" />
                                        </div>
                                    </div>
                                    <div class="mb-1 row">
                                        <label for="sales_id" class="col-md-3 col-form-label">Sales</label>
                                        <div class="col-sm-4">
                                            <div class="input-group">
                                                <input type="text" class="form-control" id="sales_id" name="sales_id" [ngModel]="DO.sales_id" aria-describedby="sales_id" disabled="true" />
                                                <button class="btn btn-info" type="button" id="btn_sales_id" (click)="openContact('INTERNAL')"><i class="fas fa-person-booth"></i></button>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control" id="sales_by" name="sales_by" [ngModel]="DO.sales_by" placeholder="Nama Sales" disabled="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-atas">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Pengirim</h4>
                            <hr />
                            <div class="mb-1 row">
                                <label for="partner_id" class="col-md-3 col-form-label">ID Pengirim<span class="text-primary">*</span></label>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Kode Pengirim" id="partner_id" [(ngModel)]="DO.partner_id" aria-describedby="partner_id" />
                                        <button class="btn btn-primary" type="button" id="btn_partner_id" (click)="openContact('CUSTOMER')"><i class="fas fa-users-cog"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="partner_name" class="col-md-3 col-form-label">Nama Pengirim<span class="text-primary">*</span></label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" value="" id="partner_name" [(ngModel)]="DO.partner_name" placeholder="Perusahaan / Perorangan">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="partner_pic" class="col-md-3 col-form-label">Shipper / PIC</label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" value="" id="partner_pic" [(ngModel)]="DO.partner_pic" placeholder="Person In Charge">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="partner_phone1" class="col-md-3 col-form-label">Telepon</label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" value="" placeholder="Telepon / mobile " [(ngModel)]="DO.partner_phone1" id="partner_phone1">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="partner_address1" class="col-md-3 col-form-label">Alamat <span class="text-primary">*</span> </label>
                                <div class="col-sm-9">
                                    <textarea class="form-control" id="partner_address1" name="partner_address1" [ngModel]="DO.partner_address1" rows="4" placeholder="Alamat lengkap"></textarea>
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="origin" class="col-md-3 col-form-label">Kota Asal <span class="text-primary">*</span></label>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="origin" [ngModel]="DO.origin" aria-describedby="origin" disabled="true" />
                                        <button class="btn btn-primary" type="button" id="origin" (click)="openModalDest('ORIGIN')"><i class="bx bx-right-top-arrow-circle "></i></button>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" id="originname" name="originname" [ngModel]="DO.originname" placeholder="Nama kota asal" disabled="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-atas">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Penerima</h4>
                            <hr />
                            <div class="mb-1 row">
                                <label for="receiver_id" class="col-md-3 col-form-label">Id Penerima</label>
                                <div class="col-md-9">
                                    <div class="input-group">
                                        <input class="form-control" type="text" value="" id="receiver_id" name="receiver_id" [(ngModel)]="DO.receiver_id" [disabled]="!DO.partner_id">
                                        <button class="btn btn-primary" type="button" id="btn_partner_id" (click)="openModalSubContact()" [disabled]="!DO.partner_id"><i class="fas fa-user-friends"></i></button>
                                    </div>


                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="receiver_name" class="col-md-3 col-form-label">Nama Penerima<span class="text-primary">*</span></label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" value="" placeholder="Penerima (perusahaan/pribadi)" id="receiver_name" name="receiver_name" [(ngModel)]="DO.receiver_name">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="receiver_pic" class="col-md-3 col-form-label">Pic</label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" value="" id="receiver_pic" name="receiver_pic" [(ngModel)]="DO.receiver_pic">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="receiver_phone1" class="col-md-3 col-form-label">Telepon</label>
                                <div class="col-md-9">
                                    <input class="form-control" type="text" value="" id="receiver_phone1" [(ngModel)]="DO.receiver_phone1">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="receiver_address1" class="col-md-3 col-form-label">Alamat <span class="text-primary">*</span></label>
                                <div class="col-sm-9">
                                    <textarea class="form-control" id="receiver_address1" name="receiver_address1" rows="4" placeholder="Alamat  lengkap penerima" [(ngModel)]="DO.receiver_address1"></textarea>
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="destination" class="col-md-3 col-form-label">Kota Tujuan<span class="text-primary">*</span></label>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="destination" aria-describedby="destination" [(ngModel)]="DO.destination" disabled="true" /><button class="btn btn-primary" type="button" id="destination" (click)="openModalDest('DESTINATION')"><i class="bx bx-right-down-arrow-circle"></i></button>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" id="destinationname" name="destinationname" placeholder="Nama kota tujuan" [(ngModel)]="DO.destinationname" disabled="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-atas align-top">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Detail Pengiriman</h4>
                            <hr />
                            <div class="mb-1 row">
                                <label for="qty" class="col-md-3 col-form-label">Koli<span class="text-primary">*</span></label>
                                <div class="col-md-3">
                                    <input class="form-control" type="number" id="qty" name="qty" [(ngModel)]="DO.qty">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="weight_actual" class="col-md-3 col-form-label">Berat<span class="text-primary">*</span></label>
                                <div class="col-md-3">
                                    <input class="form-control" type="number" id="weight_actual" name="weight_actual" [(ngModel)]="DO.weight_actual" (ngModelChange)="onChangeVol($event)">
                                </div>
                                <div class="col-md-6">
                                    <select class="form-select" [ngModel]="DO.uom_id" (ngModelChange)="onChangePickupUom($event)" id="uom_id" name="uom_id">
                                        <option [value]="i?.uom_id" *ngFor="let i of uomData">{{i.uom_desc}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="weight_vol" class="col-md-3 col-form-label">Vol<span class="text-primary">*</span></label>
                                <div class="col-md-3">
                                    <input class="form-control" type="number" value="1" id="weight_vol" name="weight_vol" [(ngModel)]="DO.weight_vol" (ngModelChange)="onChangeVol($event)">
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="weight" class="col-md-3 col-form-label">Berat Charge</label>
                                <div class="col-md-3">
                                    <input class="form-control" type="number" value="1" id="weight" name="weight" [(ngModel)]="DO.weight_invoice">
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Informasi Lain lain</h4>
                            <hr />
                            <div class="mb-1 row">
                                <label for="specialinst" class="col-md-3 col-form-label">Instruksi Pengiriman</label>
                                <div class="col-md-9">
                                    <textarea class="form-control" id="specialinst" name="specialinst" rows="4" placeholder="Keterangan handling terhadap pengiriman" [(ngModel)]="DO.specialinst"></textarea>
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="itemdesc" class="col-md-3 col-form-label">Isi barang </label>
                                <div class="col-md-9">
                                    <textarea class="form-control" id="itemdesc" name="itemdesc" rows="4" placeholder="Keterangan isi barang " [(ngModel)]="DO.itemdesc"></textarea>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card-body">
                            <div class="table-responsive" style="height: 250px;">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colspan="3">Dimensi / Volume</th>
                                            <th colspan="3"></th>
                                        </tr>
                                        <tr style="background-color: #d0d0d0;">
                                            <th style="width: 15%;">Panjang <span class="text-red">*</span></th>
                                            <th style="width: 15%;">Lebar <span class="text-red">*</span></th>
                                            <th style="width: 15%;">Tinggi <span class="text-red">*</span></th>
                                            <th style="width: 15%;">Berat Aktual <span class="text-red">*</span>
                                            </th>
                                            <th style="width: 15%;">Berat Volume</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr ng-repeat="dtItem in detailItem">
                                            <td>
                                                <input ng-model="dtItem.item_p" type="number"
                                                    class="form-control text-right" placeholder="Panjang" min="1"
                                                    ng-change="changeVol($index)" required>
                                            </td>
                                            <td>
                                                <input ng-model="dtItem.item_l" type="number"
                                                    class="form-control text-right" placeholder="Lebar" min="1"
                                                    ng-change="changeVol($index)" required>
                                            </td>
                                            <td>
                                                <input ng-model="dtItem.item_t" type="number"
                                                    class="form-control text-right" placeholder="Tinggi" min="1"
                                                    ng-change="changeVol($index)" required>
                                            </td>
                                            <td>
                                                <input ng-model="dtItem.weight" type="number"
                                                    class="form-control text-right" placeholder="Berat" min="1"
                                                    ng-change="changeVol($index)" required>
                                            </td>
                                            <td class="text-right">
                                                {{ dtItem.vol}}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="3">Total</td>
                                            <td> <input ng-model="dataOutbound.weight_actual" type="number"
                                                    class="form-control text-right" id="berat_id_act" min="1"
                                                    name="berat_id_act" placeholder="" ng-change=priceChange()></td>
                                            <td> <input ng-model="dataOutbound.vol" type="number"
                                                    class="form-control text-right" id="vol" name="vol"
                                                    placeholder="">
                                                Berat yang di gunakan :
                                                <strong>{{dataOutbound.weight|number}}</strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div> -->
                    </div>

                </div>
                <div class="col-md-6 mt-atas">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Harga Pengiriman</h4>
                            <hr />
                            <div class="mb-1 row">
                                <label for="charge" class="col-md-3 col-form-label">Biaya Kirim</label>
                                <div class="col-md-6">
                                    <input type="text" class="form-control text-end" id="charge" name="charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.charge" (ngModelChange)="onChangePrice($event)" aria-describedby="charge" />
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="pickup_charge" class="col-md-3 col-form-label">Biaya Pickup</label>
                                <div class="col-md-6">
                                    <input type="text" class="form-control text-end" id="pickup_charge" name="pickup_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.pickup_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="pickup_charge"
                                    />
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="packing_charge" class="col-md-3 col-form-label">Biaya Packing</label>
                                <div class="col-md-6">
                                    <input type="text" class="form-control text-end" id="packing_charge" name="packing_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.packing_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="packing_charge"
                                    />
                                </div>
                            </div>
                            <hr/>
                            <div class="mb-1 row">
                                <label for="sub_total" class="col-md-3 col-form-label">Sub total</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control text-end" id="sub_total" name="sub_total" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.sub_total" aria-describedby="sub_total" disabled="true" />
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="tax_percent" class="col-md-3 col-form-label">PPn</label>
                                <div class="col-md-3 m-0">
                                    <input type="text" class="form-control text-end" id="tax_percent" name="tax_percent" mask="separator.2" data-reverse="true" [(ngModel)]="DO.tax_percent" (ngModelChange)="onChangePrice($event)" aria-describedby="tax_percent" />
                                </div>
                                <label for="tax_percent" class="col-md-1 col-form-label">%</label>
                                <div class="col-md-5">
                                    <input type="text" class="form-control text-end" id="tax_amount" name="tax_amount"   data-reverse="true" disabled="true" [(ngModel)]="DO.tax_amount" aria-describedby="tax_amount" />
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="sub_total_tax" class="col-md-3 col-form-label">Sub total setelah PPn</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control text-end" id="sub_total_tax" name="sub_total_tax" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.sub_total_tax" aria-describedby="sub_total_tax" disabled="true" />
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="insurance_price" class="col-md-3 col-form-label">Nilai Asuransi </label>
                                <div class="col-md-9">
                                    <div class="input-group mb-1">
                                        <input type="text" class="form-control text-end" id="insurance_price" name="insurance_price" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.insurance_price" aria-describedby="insurance_price" [disabled]="!isInsurance" />
                                        <div class="input-group-text">
                                            <input class="form-check-input mt-0" id="isInsurance" type="checkbox" value="" aria-label="isInsurance" [(ngModel)]="isInsurance" [checked]="isInsurance">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label for="insurance_charge" class="col-md-3 col-form-label">Insurance Charge</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control text-end" id="insurance_charge" name="insurance_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.insurance_charge" (ngModelChange)="onChangePrice($event)" [disabled]="!isInsurance"
                                        aria-describedby="insurance_charge" disabled="true" />
                                </div>
                            </div>
                            <strong><hr/></strong>
                            <div class="mb-1 row">
                                <label for="total" class="col-md-3 col-form-label">Total</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control text-end" id="total" name="total" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.total" aria-describedby="total" disabled="true" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6 mt-atas">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Detail Surat Jalan</h4>
                            <hr />
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Surat jalan" aria-label="Tambah No Reff/No Surat Jalan" aria-describedby="btn-cari" [(ngModel)]="sjNo" (keyup.enter)="detailSuratJalan.cari()" />
                                <button class="btn btn-outline-info" type="button" id="btn-filter" ngbTooltip="Filter Shipment Instruksi Pickup" (click)="detailSuratJalan.cari()">
                                  <i class="fa fa-plus"></i>
                                </button>
                            </div>
                            <div class="table-responsive" style="height: 250px;">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>No Surat Jalan / Faktur</th>
                                            <th> <span class="text-red">*</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let dtItem of detailRef;let i=index">
                                            <td>
                                                {{dtItem.reff_no}}
                                            </td>
                                            <td>
                                                <button class="btn btn-primary btn-rounded btn-condensed btn-sm" (click)="detailSuratJalan.delete(i)">
                                                    <span class="bx bx-trash-alt"></span> Hapus
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-atas">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Detail Koli</h4>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">

            <div *ngIf="loadingSave" class="spinner-grow text-primary m-1" role="status">
                <span class="sr-only">Loading...</span>
            </div>


            <div class="btn-group float-end" role="group">
                <button type="button" class="btn btn-dark ">Batal</button>
                <button type="button" (click)="validasiShipment()" class="btn btn-primary">
                
                <i  *ngIf="loadingSave" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                <i  *ngIf="!loadingSave" class="fas fa-save font-size-16 align-middle me-2"></i>
                {{captionButton}}  </button>
            </div>
        </div>
    </div>


</div>